import { createTheme } from '@mui/material/styles';

export const light = createTheme({
	palette: {
		type: 'light',
		background: {
			paper: '#F3F3F3',
			default: '#fff',
		},
	},
});
