import { AnyAction } from 'redux';
import { toast } from 'react-toastify';
import isEmpty from '../../utils/is-empty';
import K from '../../constants';
import setAuthToken from '../../network/set-auth-token';
import {setComparable} from "./comparables.reducer";
import { User } from '../../@types';

export interface AuthState {
	authenticated: boolean;
	user?: User;
}

const initialState: AuthState = {
	authenticated: false,
};

export const AuthActions = {
	SET_USER: 'setUser',
};

export interface UserCredentials {
	username: string;
	password: string;
}
export function logoutUser(removeCookies: Function) {
	window.localStorage.removeItem(K.SAVED_USER);
	removeCookies(K.ACCESS_TOKEN);
	setAuthToken(null);
	setComparable(undefined);

	toast.info('Logged out successfully');
	return setUser(null);
}

export function setUser(data: any) {
	return {
		type: AuthActions.SET_USER,
		payload: data,
	};
}

export default function authReducer(state: AuthState = initialState, action: AnyAction): AuthState {
	switch (action.type) {
		case AuthActions.SET_USER: {
			return {
				...state,
				authenticated: !isEmpty(action.payload),
				user: { ...action.payload },
			};
		}
		default:
			return state;
	}
}
