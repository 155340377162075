import { createTheme } from '@mui/material/styles';

export const dark = createTheme({
	palette: {
		type: 'dark',
		background: {
			default: '#1b1b1b',
			paper: '#1d1d1d',
		},
	},
});
