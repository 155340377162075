import React from 'react';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faYoutube, faQuora } from "@fortawesome/free-brands-svg-icons";
import { FacebookIcon, TwitterIcon, LinkedinIcon, } from 'react-share';

const useStyles = makeStyles ({
    socialContainer: {
		'& > a': {
		    borderRadius: '5px',
		    margin: '0.4rem 0.8rem 0rem 0rem',
		    padding: 0,
		    alignItems: 'center',
		    transition: 'transform 250ms',
		    display: 'inline-block',
		    height: '2rem',
		    width: '2rem',
		    color: '#f3f3f3',
		    '&:hover': {
		        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)',
		        transform: 'translateY(-2px)',
		    },
		},
	},
	youtube: {
	    color: 'white',
	    background: '#eb3223',
	    '&:hover': {
	        color: 'white',
	    },
	},
	youtubeIcon: {
	    height: '35px',
	    width: '2rem!important',
	    borderRadius: '1rem',
	    margin: 0,
	    padding: '0px 5px 2px',
	},
	facebook: {
	    background: '#305a9c',
	},
	twitter: {
	    background: '#2caced',
	},
	instagram:{
	    color: 'white',
	    background: '#C13584',
	    '&:hover': {
	        color: 'white',
	    },
	},
	instagramIcon: {
	    height: '35px',
	    width: '2rem!important',
	    borderRadius: '1rem',
	    margin: 0,
	    padding: '4px 7px 6px',
	},
	linkedin: {
	    background: '#087fb1'
	},
	quora: {
	    color: 'white',
	    background: '#B92B27',
	    '&:hover': {
	        color: 'white',
	    },
	},
	quoraIcon: {
	    height: '35px',
	    width: '2rem!important',
	    borderRadius: '1rem',
	    margin: 0,
	    padding: '7px 2px 8px',
	},
	shareIcon: {
		height: '2rem',
		width: '2rem',
		borderRadius: '1rem',
		margin: '0',
		padding: 0,
	}
});

const SocialFollow: React.FC<{}> = () => {
	const classes = useStyles();

	return (
	    <div>
	        <p><b> Follow us on: </b></p>
	        <div className={classes.socialContainer}>
	            <a href="https://www.facebook.com/squaredealtax/" className={classes.facebook}
	            target='_blank' rel='noopener noreferrer'>
	                <FacebookIcon className={classes.shareIcon} />.
	            </a>
	            <a href="https://twitter.com/squaredealtax" className={classes.twitter}
	            target='_blank' rel='noopener noreferrer'>
	                <TwitterIcon className={classes.shareIcon} />.
	            </a>
	            <a href="https://www.linkedin.com/company/77842215/" className={classes.linkedin}
	            target='_blank' rel='noopener noreferrer'>
	                <LinkedinIcon className={classes.shareIcon} />.
	            </a>
	            <a href="https://www.instagram.com/square_deal_property_tax/" className={classes.instagram}
	            target='_blank' rel='noopener noreferrer'>
	                <FontAwesomeIcon icon={faInstagram} size="2x" className={classes.instagramIcon} />.
	            </a>
	            <a href="https://www.youtube.com/channel/UCL0ymcS2mO39Jc290vHwTAA" className={classes.youtube}
	            target='_blank' rel='noopener noreferrer'>
	                <FontAwesomeIcon icon={faYoutube} size="2x" className={classes.youtubeIcon} />.
	            </a>
	            <a href="https://propertytaxappeal1.quora.com/" className={classes.quora}
	            target='_blank' rel='noopener noreferrer'>
	                <FontAwesomeIcon icon={faQuora} size="2x" className={classes.quoraIcon} />.
	            </a>
	        </div>
	    </div>
	);
};

export default SocialFollow;