import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import TagManager from 'react-gtm-module';
import App from './App';
import store, { persistor } from './store';
import ThemeContextProvider from './styles/theme/ThemeContext';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import { GoogleOAuthProvider } from '@react-oauth/google';

const google_client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';

const tagManagerArgs = {
	gtmId: 'GTM-TBQH4F9',
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
	<ReduxProvider store={store}>
		<PersistGate persistor={persistor}>
			<GoogleOAuthProvider clientId={google_client_id}>
				<ThemeContextProvider>
					<App />
				</ThemeContextProvider>
			</GoogleOAuthProvider>
		</PersistGate>
	</ReduxProvider>,
	document.getElementById('root')
);
