import React from 'react';
import { makeStyles } from "@mui/styles";
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import {
	FacebookShareButton,
	FacebookIcon,
	TwitterIcon,
	TwitterShareButton,
	RedditShareButton,
	RedditIcon,
	WhatsappShareButton,
	WhatsappIcon,
	LinkedinShareButton,
	LinkedinIcon,
} from 'react-share';
import SocialFollow from '../SocialFollow';

const useStyles = makeStyles ({
	footer: {
		background: '#F3F3F3',
		padding: '2rem',
		'@media (max-width: 768px)': {
			flexDirection: 'column',
		},
		'@media (max-width: 641px)': {
		    padding: '1rem',
		},
		'@media (min-width: 1200px)': {
		    padding: '2rem 5rem',
		},
		'@media (min-width: 2500px)': {
			fontSize: '1.5rem',
			padding: '3rem 5.5rem',
		},
	},
	terms: {
		//display: 'flex',
		alignItems: 'center',
	},
	link: {
		color: '#0A66C2',
		textDecoration: 'none',
		borderLeft: '1px solid #A49595',
		padding: '0rem .5rem',
		'@media (max-width: 325px)': {
			padding: '0rem .2rem',
		},
	},
	links: {
		display: 'grid',
	},
	subLinks: {
		color: '#0A66C2',
		textDecoration: 'none',
	},
	p1: {
		marginBottom: '0.2rem',
	},
    shareBtn: {
		background: '#fff',
		borderRadius: '5px',
		margin: '0.4rem 0.8rem 0rem 0rem',
		padding: 0,
		display: 'flex',
		alignItems: 'center',
		transition: 'transform 250ms',
		'&:hover': {
		    transform: 'translateY(-2px)',
		    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)',
		},
	},
	shareIcon: {
		height: '2rem',
		width: '2rem',
		borderRadius: '1rem',
		margin: '0',
		padding: 0,
	},
	socialIcon: {
	    marginTop: '1.5rem',
	    '& > div': {
	        display: 'flex',
	        alignItems: 'center',
	        '@media (max-width: 1000px)': {
	            display: 'block',
	        },
	        '& > p': {
	            paddingRight: '0.5rem',
	            margin: 0,
	        },
	        '& > div': {
	            display: 'flex',
	        },
	    },
	},
});

const Footer: React.FC<{}> = () => {
	const classes = useStyles();

	return (
		<footer className={classes.footer}>
			<Grid container spacing={3}>
				<Grid item md={4} sm={12} xs={12}>
					<p className={classes.p1}><b>Property Tax Appeal - Do it Yourself. Do it Right.</b></p>
					<div className={classes.links}>
						<Link to='/about/' className={classes.subLinks}>
							About
						</Link>
						<Link to='/pricing/' className={classes.subLinks}>
							Pricing
						</Link>
						<a
							href={process.env.REACT_APP_BLOG_URL}
							target='_blank'
							className={classes.subLinks}
							rel='noopener noreferrer'
						>
							Blog
						</a>
						<Link to='/contact/' className={classes.subLinks}>
							Contact us
						</Link>
						<Link to="/supported-counties/" className={classes.subLinks}>
							Supported counties
						</Link>
						<Link to="/refer-a-friend/" className={classes.subLinks}>
							Refer a friend
						</Link>
					</div>
				</Grid>
				<Grid item md={4} sm={12} xs={12}>
					<div>
						<p className={classes.p1}>
							<b>Quick links :</b>
						</p>
					</div>
					<div className={classes.links}>
						<a
							href='https://blog.squaredeal.tax/Can-a-tax-assessor-enter-my-property-in-Texas/'
							target='_blank'
							className={classes.subLinks}
							rel='noopener noreferrer'
						>
							Can a tax assessor enter my property in Texas?
						</a>
						<a
							href='https://blog.squaredeal.tax/texas-homestead-exemption/'
							target='_blank'
							className={classes.subLinks}
							rel='noopener noreferrer'
						>
							How much property tax does homestead exemption save?
						</a>
						<a
							href='https://blog.squaredeal.tax/9-proven-methods-to-reduce-your-property-tax-bill-immediately/'
							target='_blank'
							className={classes.subLinks}
							rel='noopener noreferrer'
						>
							9 Proven methods to reduce your property tax bill
						</a>
						<a
							href='https://blog.squaredeal.tax/texas/harris-county-property-tax-protests/'
							className={classes.subLinks}
							target='_blank'
							rel='noopener noreferrer'
						>
							Do homeowners who protest by themselves save more?
						</a>
						<a
							href='https://blog.squaredeal.tax/why-your-neighbors-property-tax-bill-is-lower-than-yours/'
							className={classes.subLinks}
							target='_blank'
							rel='noopener noreferrer'
						>
							5 reasons why your neighbor's tax bill is lower
						</a>
						<a
							href='https://blog.squaredeal.tax/texas-solar-property-tax-exemption/'
							className={classes.subLinks}
							target='_blank'
							rel='noopener noreferrer'
						>
							Do solar panels increase your property taxes in Texas?
						</a>
						<a
							href='https://blog.squaredeal.tax/texas/appraisal-review-board-hearing/'
							className={classes.subLinks}
							target='_blank'
							rel='noopener noreferrer'
						>
							How to prepare for an ARB hearing?
						</a>
					</div>
				</Grid>
				<Grid item md={4} sm={12} xs={12}>
					<div>
						<p className={classes.p1}>
							<b> © {new Date().getFullYear()} SQD Taxtech LLC. All rights reserved.</b>
						</p>
						<div className={classes.terms}>
							<Link
								to='/terms-of-service/'
								className={classes.link}
								target='_blank'
								rel='noopener noreferrer'
							>
								Terms of service
							</Link>{' '}
							<Link
								to='/privacy-policy/'
								className={classes.link}
								target='_blank'
								rel='noopener noreferrer'
							>
								Privacy policy
							</Link>
							<Link
							    to='/refund-policy/'
							    className={classes.link}
							    target='_blank'
							    rel='noopener noreferrer'
							>
							    Refund policy
							</Link>
							<Link
								to='/custom-comps-report-request/'
								className={classes.link}
								target='_blank'
								rel='noopener noreferrer'
							>
								Custom Report
							</Link>
							<Link
								to='/cad-evidence-review/'
								className={classes.link}
								target='_blank'
								rel='noopener noreferrer'
							>
								Cad Evidence Review
							</Link>
					    </div>
					</div>
					<div className={classes.socialIcon}>
						<div>
						  <p><b> Share us on :</b></p>
						  <div>
						    <FacebookShareButton
						        url="https://squaredeal.tax"
						        className={classes.shareBtn}
						        style={{ background: '#305a9c'}}
						    >
						        <FacebookIcon className={classes.shareIcon} />
						    </FacebookShareButton>
						    <TwitterShareButton
						        url="https://squaredeal.tax"
						        className={classes.shareBtn}
						        style={{ background: '#2caced'}}
						    >
						        <TwitterIcon className={classes.shareIcon} />
						    </TwitterShareButton>
						    <LinkedinShareButton
						        url="https://squaredeal.tax"
						        className={classes.shareBtn}
						        style={{ background: '#087fb1'}}
						    >
						        <LinkedinIcon className={classes.shareIcon} />
						    </LinkedinShareButton>
						    <RedditShareButton
						        url="https://squaredeal.tax"
						        className={classes.shareBtn}
						        style={{ background: '#f34423'}}
						    >
						        <RedditIcon className={classes.shareIcon} />
						    </RedditShareButton>
						    <WhatsappShareButton
						        url="https://squaredeal.tax"
						        className={classes.shareBtn}
						        style={{ background: '#64d467'}}
						    >
						        <WhatsappIcon className={classes.shareIcon} />
						    </WhatsappShareButton>
						  </div>
						</div>
					</div>

					{/* Social media links to Follow us */}
					<div className={classes.socialIcon}>
					    <SocialFollow />
					</div>
				</Grid>
			</Grid>
		</footer>
	);
};

export default Footer;