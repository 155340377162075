import { AnyAction } from 'redux';

export interface MiscState {
	activeIndex: number;
}

export const initialState: MiscState = {
	activeIndex: 0,
};

export const MiscActions = {
	SET_ACTIVE_INDEX: 'SET_ACTIVE_INDEX',
};

export function setActiveIndex(index: number) {
	return {
		type: MiscActions.SET_ACTIVE_INDEX,
		payload: index,
	};
}

export default function miscReducer(state: MiscState = initialState, action: AnyAction): MiscState {
	switch (action.type) {
		case MiscActions.SET_ACTIVE_INDEX:
			return { ...state, activeIndex: action.payload };
		default:
			return state;
	}
}
