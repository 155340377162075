import { combineReducers } from 'redux';
import authReducer, { AuthState } from './modules/auth.reducer';
import comparablesReducer, { ComparablesState } from './modules/comparables.reducer';
import miscReducer, { MiscState } from './modules/misc.reducer';

export interface RootState {
	auth: AuthState;
	comparables: ComparablesState;
	misc: MiscState;
}

const rootReducer = combineReducers<RootState>({
	auth: authReducer,
	comparables: comparablesReducer,
	misc: miscReducer,
});

export default rootReducer;
