import React from "react";
import { Helmet } from "react-helmet-async";
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import ProgressiveImage from "react-progressive-graceful-image";
import Footer from "../../components/Footer";
import SupportedCountiesTable from "../../components/SupportedCountiesTable";
import LeadCaptureForm from "../../components/LeadCaptureForm";

const useStyles = makeStyles ({
	firstGrid: {
		background:
			"linear-gradient(-30deg, white 50%, white 30%, white 40%, #E5F2FF 35%, #E5F2FF 60% )",
		padding: "1rem 2rem",
		'@media (max-width: 820px)': {
			padding: "1rem",
		},
		"& > h1": {
			fontSize: "2rem",
			color: "#0b66c2",
			letterSpacing: "1px",
			'@media (max-width: 820px)': {
				fontSize: "1.5rem",
			},
		},
	},
	secondGrid: {
		background:
			"linear-gradient(30deg, white 50%, white 30%, white 40%, #E5F2FF 35%, #E5F2FF 60% )",
		padding: "3rem 2rem 1rem",
		justifyContent: "center",
		alignItems: "center",
		display: "inline-flex",
		'@media (max-width: 820px)': {
			padding: "1rem",
		},
		'& > img': {
		    '@media (min-width: 1880px)': {
		        width: '45%',
		    },
		},
	},
	thirdGrid: {
	    '& > div > form > div': {
	        backgroundColor: 'white',
	        marginLeft: 0,
	        padding: 0,
	        width: '100%',
	        '& > div:nth-child(1)': {
	            display: 'none',
	        },
	        '& > div:nth-child(2)': {
	            display: 'none',
	        },
	        '& > div:nth-child(3) > div': {
	            display: 'block',
	            padding: '0rem 1rem',
	            '& > h2': {
	                fontSize: '1.8rem',
	                padding: '2rem 0rem 0rem',
	                lineHeight: '2.2rem',
	                color: 'black',
	                fontWeight: 'normal',
	                '@media (max-width: 820px)': {
	                    fontSize: '1.3rem',
	                },
	            },
	            '& > span': {
	                fontSize: '1.2rem',
	                '@media (max-width: 960px)': {
	                    fontSize: '1rem',
	                },
	            },
	        },
	        '& > div:nth-child(4)': {
	            '& > div:nth-child(1)': {
	                display: 'none',
	            },
	            '& > div:nth-child(2)': {
	                display: 'block',
	                margin: 'auto',
	                maxWidth: '60%',
	                flexBasis: '60%',
	                border: 'none',
	                '@media (max-width: 768px)': {
	                    maxWidth: '100%',
	                    flexBasis: '100%',
	                },
	                '@media (min-width: 2000px)': {
	                    maxWidth: '40%',
	                    flexBasis: '40%',
	                },
	                '& > div:nth-child(1)': {
	                    display: 'none',
	                },
	                '& > div:nth-child(6)': {
	                    display: 'block',
	                },
	                '& > div:nth-child(7)': {
	                    '& > button > span > span:nth-child(1)': {
	                        display: 'none',
	                    },
	                    '& > button > span > span:nth-child(2)': {
	                        display: 'block',
	                    },
	                    '& > p:nth-child(3)': {
	                        display: 'block',
	                    },
	                    '& > p:nth-child(2)': {
	                        display: 'none',
	                    },
	                },
	            },
	        },
	    },
	},
});

const SupportedCounties: React.FC<{}> = () => {
	const classes = useStyles();
	return (
		<div>
			<Helmet>
				<title>Square Deal - Supported counties</title>
				<link rel='icon' href='https://res.cloudinary.com/agiliti/image/upload/v1639465069/SDIcon.png' />
				<meta name='description' content='You are now just a few clicks away from being able to reduce your
				property taxes by hundreds or even thousands of dollars. Submit your appeal by the deadline and
				get the money you deserve.' />
			</Helmet>
			<Grid container>
				<Grid item md={6} sm={12} xs={12} className={classes.firstGrid}>
					<h1>Supported counties</h1>
					<SupportedCountiesTable />
				</Grid>
				<Grid item md={6} sm={12} xs={12} className={classes.secondGrid}>
					<ProgressiveImage
					    src='https://res.cloudinary.com/agiliti/image/upload/v1639380144/supportedCounties.webp'
					    placeholder='https://res.cloudinary.com/agiliti/image/upload/v1639380144/supportedCountiesBlur.webp'
					q>
						{(src: string) => (
							<img src={src} alt='Contact Us!' width='60%' height='auto' title='Our supported counties' />
						)}
					</ProgressiveImage>
				</Grid>
				<Grid item md={12} sm={12} xs={12} className={classes.thirdGrid}>
				    <LeadCaptureForm />
				</Grid>
			</Grid>

			<Footer />
		</div>
	);
};

export default SupportedCounties;