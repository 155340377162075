import { AnyAction } from 'redux';
import { PropertyInterface } from './../../@types';

export interface ComparablesState {
	subjectProperty?: PropertyInterface[];
	comparableProperties?: PropertyInterface[];
	page: string;
}

const initialState: ComparablesState = {
	comparableProperties: [],
	subjectProperty: [],
	page: 'Subject Property',
};

export const ComparablesActions = {
	SET_COMPARABLE: 'SET_COMPARABLE',
	SET_SUBJECT_PROPERTY: 'SET_SUBJECT_PROPERTY',
	SET_COMPARABLE_PROPERTIES: 'SET_COMPARABLE_PROPERTIES',
};

export function setComparable(data?: ComparablesState) {
	return {
		type: ComparablesActions.SET_COMPARABLE,
		payload: data,
	};
}

export function setSubjectProperty(property: PropertyInterface) {
	return {
		type: ComparablesActions.SET_SUBJECT_PROPERTY,
		payload: property,
	};
}

export function setComparableProperties(properties: PropertyInterface[]) {
	return {
		type: ComparablesActions.SET_COMPARABLE_PROPERTIES,
		payload: properties,
	};
}

export default function comparablesReducer(
	state: ComparablesState = initialState,
	action: AnyAction
): ComparablesState {
	switch (action.type) {
		case ComparablesActions.SET_COMPARABLE:
			return {
				...state,
				...action.payload,
			};
		case ComparablesActions.SET_SUBJECT_PROPERTY:
			return {
				...state,
				subjectProperty: action.payload,
			};
		case ComparablesActions.SET_COMPARABLE_PROPERTIES:
			return {
				...state,
				comparableProperties: action.payload,
			};
		default:
			return state;
	}
}
