import { lazy } from 'react';
import { RouteConfig } from 'react-router-config';
import MainLayout from './layouts/MainLayout';
import SupportedCounties from './pages/SupportedCounties';

const routes: RouteConfig[] = [
	{
		path: '',
		component: MainLayout,
		routes: [
			// homepage
			{
				path: '/',
				exact: true,
				component: lazy(() => import('./pages/Landing')),
			},

			// "save on your property tax" form
			{
				path: '/lower-my-property-taxes/',
				exact: true,
				component: lazy(() => import('./pages/LeadCapture')),
			},

			// about-us page
			{
				path: '/about/',
				exact: true,
				component: lazy(() => import('./pages/About/About')),
			},

			// contact page
			{
				path: '/contact/',
				exact: true,
				component: lazy(() => import('./pages/Contact/Contact')),
			},

			// pricing page
			{
				path: '/pricing/',
				exact: true,
				component: lazy(() => import('./pages/Pricing/Pricing')),
			},

			// data deletion form
			{
				path: '/data-deletion-request/',
				exact: true,
				component: lazy(() => import('./pages/Contact/Contact')),
			},

			// terms of service page
			{
				path: '/terms-of-service/',
				exact: true,
				component: lazy(() => import('./pages/TermsOfService/TermsConditions')),
			},

			// refund policy page
			{
			    path: '/refund-policy/',
			    exact: true,
			    component: lazy(() => import('./pages/RefundPolicy')),
			},

			// Custom report request
			{
				path: '/custom-comps-report-request/',
				exact: true,
				component: lazy(() => import('./pages/CustomReport')),
			},

			// Cad evidence review
			{
				path: '/cad-evidence-review/',
				exact: true,
				component: lazy(() => import('./pages/CadEvidenceReview')),
			},

			// privacy policy page
			{
				path: '/privacy-policy/',
				exact: true,
				component: lazy(() => import('./pages/PrivacyPolicy/Privacy')),
			},

			// ThankYou page
			{
				path: '/thank-you/',
				exact: true,
				component: lazy(() => import('./pages/ThankYou')),
			},

			// refer a friend page
			{
				path: '/refer-a-friend/',
				exact: true,
				component: lazy(() => import('./pages/ReferAFriend')),
			},

			// login route
			{
				path: '/login/',
				exact: true,
				component: lazy(() => import('./pages/Login')),
			},

			// report summary page
			{
				path: '/properties/report-summary/',
				exact: true,
				component: lazy(() => import('./pages/ReportSummary')),
			},

			// Supported Counties page
			{
				path: '/supported-counties/',
				exact: true,
				component: SupportedCounties,
			},

			// New Homeowner page
			{
				path: '/new-homeowner/',
				exact: true,
				component: lazy(() => import('./pages/NewHomeowner')),
			},

			// Payment Success page
			{
			    path: '/payment-success/',
			    exact: true,
			    component: lazy(() => import('./pages/PaymentStatus/PaymentSuccess')),
			},

			// Payment Incomplete page
			{
			    path: '/payment-incomplete/',
			    exact: true,
			    component: lazy(() => import('./pages/PaymentStatus/PaymentIncomplete')),
			},

			// Payment Failed page
			{
			    path: '/payment-failed/',
			    exact: true,
			    component: lazy(() => import('./pages/PaymentStatus/PaymentFailed')),
			},

			// 404 route
			{
				path: '/404/',
				exact: true,
				component: lazy(() => import('./components/NotFound')),
			},

			// 500 route
			{
			    path: '/500/',
			    exact: true,
			    component: lazy(() => import('./components/InternalServerError')),
			},

			// Default - 404 Not Found for unknown paths
			{
				path: '*',
				exact: true,
				component: lazy(() => import('./components/NotFound')),
			},
			// if you add any new routes here, be sure to add them in the nginx config as well.
			// https://gitlab.com/agiliti-techpartners/squaredeal/square-deal-deployment/-/tree/master/nginx_conf
		],
	},
];

export default routes;
