import React, {useEffect, useState} from 'react';
import { Grid, Button, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Select from 'react-select-material-ui';
import SubmitSuccess from '../SubmitSuccess';
import {useCookies} from "react-cookie";

const DELAY = 10000;

const curr_date = new Date();
const curr_year = curr_date.getFullYear();

const useStyles = makeStyles ({
    mainGrid: {
        width: '100%!important',
        padding: '1rem',
        margin: '0!important',
        backgroundColor: "#f2f8ff",
    },
    gridHeading: {
        textAlign: 'center',
        padding: '0!important',
        '& > h2': {
            fontSize: '1.875rem',
            lineHeight: '2.2rem',
            color: 'black',
            marginTop: 0,
            '@media (max-width: 960px)': {
                margin: 0,
            },
            '@media (min-width: 2500px)': {
                fontSize: '2.5rem',
            },
		},
    },
    gridImage: {
        height: '2.5rem',
        float: 'left',
        padding: '0!important',
        '& > img': {
            padding: 0,
            position: 'relative',
            top: '-4rem',
            right: '2.15rem',
        },
    },
    subGrid: {
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        '& > h2': {
            fontSize: '1.875rem',
            marginBottom: 0,
            lineHeight: '2.2rem',
            color: 'black',
            fontWeight: 'normal',
            '@media (max-width: 960px)': {
                margin: 0,
            },
            '@media (min-width: 2500px)': {
                fontSize: '2.5rem',
            },
        },
    },
    submitButton: {
        color: 'white!important',
        backgroundColor: '#0a66c2!important',
        fontFamily: 'Lato!important',
        fontWeight: 'bold!important',
        fontSize: '1rem!important',
        width: '100%',
        borderRadius: '4px',
        margin: '0.5rem 0rem 0rem!important',
        border: '2px solid #0a66c2',
        '&:hover': {
            backgroundColor: '#3882cc',
        },
        '@media (max-width: 725px)': {
            width: '100%',
        },
    },
    inputGrid: {
        margin: '1rem 6rem 2rem!important',
        textAlign: 'right',
        '& > div:nth-child(1)': {
            paddingRight: '4rem',
            '& > h2': {
                margin: 0,
                fontSize: '1.5rem',
            },
        },
        '@media (max-width: 960px)': {
            textAlign: 'left',
        },
        '@media (max-width: 825px)': {
            margin: '0!important',
            '& > div:nth-child(1)': {
                padding: '1rem 0rem',
            },
        },
        '@media (min-width: 2000px)': {
            margin: '2rem 10rem!important',
        },
        '& > div > span': {
            fontSize: '1rem',
        },
        '& > div > h3': {
            margin: '10px 0',
        },
    },
    inputField: {
        width: '100%',
        '& > label': {
            fontSize: '1rem',
            fontFamily: 'Lato',
            color: '#313131',
        },
    },
    stateCountySection: {
        '& > div': {
            width: '100%',
            borderBottom: '1px solid #949494',
            '& > label': {
                fontSize: '1rem',
                fontFamily: 'Lato',
                fontWeight: 500,
                color: '#313131',
            },
            '& > div > div': {
                border: 'none',
                textAlign: 'left',
                fontWeight: 500,
                color: 'rgba(0, 0, 0, 0.54)',
                fontFamily: 'Lato',
            },
        },
    },
    errorMessage: {
        color: 'red',
    },
    supportedCounty: {
        display: 'none',
    },
    formSection: {
        padding: '12px!important',
        background: 'white',
        border: '1px solid #0a66c2',
        borderRadius: '4px',
        '& > div': {
            padding: '0rem 0rem 0.7rem',
        },
    },
});

const LeadCapture = () => {
	const classes = useStyles();
	const [, setCookie] = useCookies(['lead_captured']);
	const [emailId, setEmailId] = useState(null);
	const [county, setCounty] = useState('');
	const [state, setState] = useState('TX');
	const [address, setAddress] = useState('');
	const [feedback, setFeedback] = useState('');
	const [countyList, setCountyList] = useState([]);
	const [showForm, setShowForm] = useState(true);
	const [showThankYou, setShowThankYou] = useState(false);
    const [stateError, setStateError] = useState(false);
    const [countyError, setCountyError] = useState(false);

	const source = 'LEAD_CAPTURE';

	useEffect(() => {
        // hardcoding state code to TX, since that is the only state supported now.
        fetch(process.env.REACT_APP_API_URL + '/api/us_state_county/?state_code=TX')
            .then(response => response.json())
            .then(data => setCountyList(data.map(d => ({
                "value": d.county_name,
                "label": d.county_name
            }))));
	}, []);

    const setCountyHandler = (selectedOption) => {
        setCounty(selectedOption);
        (selectedOption) ? setCountyError(false) : setCountyError(true);
    }

	const handleSubmit = async (e) => {
		e.preventDefault();
        (state) ? setStateError(false) : setStateError(true);
        (county) ? setCountyError(false) : setCountyError(true);

        if (state && county) {
            const api_url = process.env.REACT_APP_API_URL + '/api/lead/';
            const response = fetch(api_url, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    last_name: emailId,
                    email_id: emailId,
                    address: address,
                    source: source,
                    county: county,
                    state: state,
                    notes: feedback
                })
            });
            await response.json;
            response.then(() => {
                // If user has subscribed, then we don't show lead capture form for 6 months.
                // Why 6 months? Why not "never show"?
                // Well, in case they unsubscribed, then we try to re-capture.
                setCookie('lead_captured', new Date(Date.now() + 180 * (1000 * 60 * 60 * 24)))

                // clear state
                setEmailId('');
                setState('');
                setCounty('');
                setAddress('');
                setFeedback('');

                // Show thank you message
                setShowForm(false);
                setShowThankYou(true);
                setTimeout(() => {
                    setShowForm(true);
                    setShowThankYou(false);
                }, DELAY);
            });
            setShowForm(false);
            setShowThankYou(true);
        }
	}

	return (
	<div>
		{ showForm &&
	    <form name='subscribe' onSubmit={handleSubmit}>
	        <Grid container spacing={3} className={classes.mainGrid}>
	            <Grid item md={12} sm={12} xs={12}></Grid>
	            <Grid item md={12} sm={12} xs={12} className={classes.gridHeading}>
	                <h2>SAVE BIG on your {curr_year} property tax!</h2>
	            </Grid>

	            <Grid item md={12} sm={12} xs={12} className={classes.subGrid} style={{padding:'0',}}>
	                <div className={classes.supportedCounty}>
	                    <h2>Can’t find your county?</h2>
	                    <span>Let us know. We will look at adding support for your county.</span>
	                </div>
	            </Grid>

	            <Grid container spacing={3} className={classes.inputGrid}>
	                <Grid item md={7} sm={12} xs={12}>
	                    <h2>Spooked by your appraisal notice?</h2>
	                    <h3>Want to find out how much you can save?</h3>
	                    <span>Our systems can identify your savings in a min and notify you!</span>
	                </Grid>

	                <Grid item md={5} sm={12} xs={12} className={classes.formSection}>
	                    <div className={classes.gridImage}>
	                        <img
	                            src='https://res.cloudinary.com/agiliti/image/upload/v1651820425/free_enrollment.webp'
	                            alt='Enroll now for free'
	                            title='Enroll now for free'
	                            height='142px'
	                            width='200px'
	                        />
	                    </div>
	                    <TextField
	                        id='subscriber-email'
	                        label='Email'
	                        variant='standard'
	                        aria-required='true'
	                        type='email'
	                        name='subscriber-email'
	                        required
	                        className={classes.inputField}
	                        onChange={(e) => setEmailId(e.target.value)}
	                    />
	                    <TextField
	                        id='subscriber-address'
	                        label='Property address'
	                        variant='standard'
	                        type='text'
	                        name='address'
	                        required
	                        className={classes.inputField}
	                        onChange={(e) => setAddress(e.target.value)}
	                    />
	                    <div className={classes.stateCountySection}>
	                        <TextField
	                            id='subscriber-state'
	                            label='State'
	                            variant='standard'
	                            type='text'
	                            name='state'
	                            required
	                            className={classes.inputField}
	                            value="Texas"
	                            disabled
	                            // onChange={setStateHandler}
	                        />
	                        { stateError && <span className={classes.errorMessage}>Select your State</span>}
	                    </div>
	                    <div className={classes.stateCountySection}>
	                        <Select
	                            id='subscriber-county'
	                            name='county-select'
	                            label='Type and select your county'
	                            required
	                            options={countyList}
	                            onChange={setCountyHandler}
	                        />
	                        {countyError && <span className={classes.errorMessage}>Select your County</span>}
	                    </div>

	                    <div className={classes.supportedCounty}>
	                        <TextField
	                            id='enter-feedback'
	                            label='Feedback'
	                            name='your_feedback'
	                            multiline
	                            rows={3}
	                            variant='standard'
	                            className={classes.inputField}
	                            onChange={(e) => setFeedback(e.target.value)}
	                        />
	                    </div>
	                    <div className={classes.subGrid}>
	                        <Button
	                            type='primary'
	                            htmltype='submit'
	                            shape='round'
	                            className={classes.submitButton}
	                        >
	                            <span>GET NOTIFIED!</span>
	                            <span className={classes.supportedCounty}>Submit</span>
	                        </Button>
	                        <p>
	                            We will never sell/rent your data to any 3rd party.That's our promise. You can unsubscribe anytime.
	                        </p>
	                        <p className={classes.supportedCounty}>
	                            We may contact you regarding your query. We will never sell/rent your data to any 3rd party. That's our promise.
	                        </p>
	                    </div>
	                </Grid>
	            </Grid>
	        </Grid>
	    </form>
		}
		{ showThankYou && <SubmitSuccess />}
	</div>
	);
};

export default LeadCapture;