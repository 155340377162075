import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { GoogleSignInResponse } from '../@types';
import { RootState } from '../store/root';

/**
 * A custom hook to sign in with google one tap
 * @param callback - callback function to be called after successfully signing in
 */
function useGoogleOneTap({
	callback,
}: {
	callback: (response: GoogleSignInResponse) => Promise<void>;
}) {
	const auth = useSelector((state: RootState) => state.auth);

	useEffect(() => {
		let google = (window as any).google;
		if (!auth.authenticated && google) {
			google?.accounts.id.initialize({
				client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
				auto_select: true,
				cancel_on_tap_outside: false,
				allowed_parent_origin: ["https://squaredeal.tax", "https://blog.squaredeal.tax", "http://localhost"],
				state_cookie_domain: "squaredeal.tax",
				callback,
			});
			google?.accounts.id.prompt((notification: any) => console.log("notification"));
		}
	}, [auth, callback]);
}

export default useGoogleOneTap;
