import React from 'react';
import { Button, Dialog, DialogActions,
DialogContent, DialogTitle } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import HighlightOff from '@mui/icons-material/HighlightOff';

interface Props {
	open: boolean;
	onClose: () => void;
	time: string;
	logOut: () => void;
}

const useStyles = makeStyles ({
	modalTop: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '.5rem 0 .5rem 1.5rem',
		alignItems: 'center',
		background: '#0A66C2',
		color: '#fff',
		fontFamily: 'Lato',
		fontWeight: 600,
	},
	icon: {
		color: '#fff',
	},
	modalButton: {
		background: '#0A66C2',
		padding: '.2rem 2rem',
		color: '#fff',
		'&:hover': {
			background: '#0A66C2',
			color: '#fff',
		},
	},
});

const IdleModal: React.FC<Props> = ({ open, onClose, time, logOut }) => {
	const classes = useStyles();

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle style={{ padding: 0 }}>
				<div className={classes.modalTop}>
					<span>Session Timeout Warning!</span>
					<IconButton onClick={onClose}>
						<HighlightOff className={classes.icon} />
					</IconButton>
				</div>
			</DialogTitle>
			<DialogContent>
				<p>
					Your session will expire in <strong>{time}</strong>. <br /> Do you want to extend the
					session?
				</p>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" color="primary" onClick={logOut}>
					Log off now
				</Button>
				<Button onClick={onClose} variant="contained" className={classes.modalButton}>
					Continue Session
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default IdleModal;
