import React from 'react';
import K from '../../constants';

const currentTheme = window.localStorage.getItem(K.STORAGE_KEYS.THEME_KEY);

export interface IThemes {
	theme: string;
	toggleTheme: (v: any) => any;
}

const initialState = {
	theme: currentTheme ? currentTheme : 'light',
	toggleTheme: () => {},
};

export const ThemeContext = React.createContext<IThemes>(initialState);

const Provider: React.FC = ({ children }) => {
	const [theme, setTheme] = React.useState(initialState.theme);

	const toggleTheme = (newTheme: string) => {
		setTheme(newTheme);
		window.localStorage.setItem(K.STORAGE_KEYS.THEME_KEY, newTheme);
	};

	return <ThemeContext.Provider value={{ theme, toggleTheme }}>{children}</ThemeContext.Provider>;
};

export default Provider;
