import React from 'react';
import { Avatar, IconButton, Drawer, Hidden,
List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import { connect, ConnectedProps } from 'react-redux';
// import clsx from 'clsx';
import { RootState } from '../../store/root';
import { logoutUser } from '../../store/modules/auth.reducer';
import { useCookies } from 'react-cookie';
import { useHistory, NavLink, useLocation } from 'react-router-dom';
import K from '../../constants';

export interface MobileNavProps {
	mobileNavOpen: boolean;
	onClose: (event?: any) => void;
}

type Props = ConnectedProps<typeof connector> & MobileNavProps;

const useStyles = makeStyles ({
	nav: {
		display: 'flex',
		flexDirection: 'column',
		padding: '1em',
	},
	navLink: {
		fontWeight: 'bold',
		fontFamily: 'inherit',
		color: 'black',
		textDecoration: 'none!important',
		margin: '0.5rem',
		letterSpacing: '0.5px',
	},
	activeLink: {
		borderBottom: `2px solid #0a66c2`,
		width: '3rem',
	},
    activeLinkC: {
		borderBottom: `2px solid #0a66c2`,
		width: '5rem',
	},
	loginButton: {
		padding: '.5rem 1rem',
		background: '#0a66c2',
		color: '#fff',
		borderRadius: '5px',
	},
	profileIcon: {
	    display: 'flex',
	    alignItems: 'center',
	    textAlign: 'center',
	    '& > button': {
	        margin: '0!important',
	        '& > div': {
	            backgroundColor: '#0a66c2!important'
	        },
	    },
	},
});

const MobileNav: React.FC<Props> = ({ mobileNavOpen, onClose, auth, logoutUser }) => {
	const [, , removeCookies] = useCookies();
	const history = useHistory();
	const location = useLocation();
	// const { theme, toggleTheme } = useContext(ThemeContext);
	const styles = useStyles();

	const logout = () => {
		logoutUser(removeCookies);
		history.push('/');
	};

	// const changeTheme = (e: any) => {
	// 	toggleTheme(e.target.checked ? 'dark' : 'light');
	// };

	const navShown = () => {
		return (
			location.pathname === '/' ||
			location.pathname === '/about/' ||
			location.pathname === '/contact/' ||
			location.pathname === '/pricing/' ||
			location.pathname === '/privacy-policy/' ||
			location.pathname === '/terms-of-service/' ||
			location.pathname === '/thank-you/' ||
			location.pathname === '/supported-counties/' ||
			location.pathname === '/refund-policy/' ||
			location.pathname === '/404/' ||
			location.pathname === '/500/' ||
			location.pathname === '/refer-a-friend/' ||
			location.pathname === '/lower-my-property-taxes/'
		);
	};

	const authenticatedLinksHidden =
		location.pathname === '/' ||
		location.pathname === '/login/' ||
		location.pathname === '/about/' ||
		location.pathname === '/contact/' ||
		location.pathname === '/pricing/' ||
		location.pathname === '/privacy-policy/' ||
		location.pathname === '/terms-of-service/' ||
		location.pathname === '/magicAuth/';

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
	    setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
	    setAnchorEl(null);
	};

	return (
		<Hidden mdUp>
			<Drawer open={mobileNavOpen} onClose={onClose}>
				<List style={{backgroundColor:'white'}}>
					{auth.authenticated && !authenticatedLinksHidden ? (
						<>
						    <ListItem button>
								<ListItemIcon>
								    <Box className={styles.profileIcon}>
								        <Tooltip title="My Account">
								            <IconButton
								                onClick={handleClick}
								                size="small"
								                sx={{ ml: 2 }}
								                aria-controls={open ? 'account-menu' : undefined}
								                aria-haspopup="true"
								                aria-expanded={open ? 'true' : undefined}
								            >
								                {auth.user?.profile_pic_url ? (
								                    <Avatar src={auth.user.profile_pic_url} />
								                ) : (
								                    <Avatar>
								                        {auth.user?.display_name
								                            ? auth.user?.display_name.substring(0, 2).toUpperCase()
								                            : window.localStorage
								                                .getItem(K.SAVED_USER_EMAIL_ADDRESS)
								                                ?.substring(0, 2)
								                                ?.toUpperCase()
								                        }
								                    </Avatar>
								                )}
								            </IconButton>
								        </Tooltip>
								    </Box>
								    <Menu
								        anchorEl={anchorEl}
								        id="account-menu"
								        open={open}
								        onClose={handleClose}
								        onClick={handleClose}
								        PaperProps={{
								            elevation: 0,
								            sx: {
								                overflow: 'visible',
								                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
								                mt: 1.5,
								                '& .MuiAvatar-root': {
								                    width: 32,
								                    height: 32,
								                    ml: -0.5,
								                    mr: 1,
								                },
								                '&:before': {
								                    content: '""',
								                    display: 'block',
								                    position: 'absolute',
								                    top: 0,
								                    right: 14,
								                    width: 10,
								                    height: 10,
								                    transform: 'translateY(-50%) rotate(45deg)',
								                    zIndex: 0,
								                },
								            },
								        }}
								        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
								        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
								    >
								        <MenuItem title='Logout' onClick={() => { onClose(); logout(); }} sx={{backgroundColor:'white'}}>
								            <ListItemIcon>
								                <Logout fontSize="small" />
								            </ListItemIcon>
								            Logout
								        </MenuItem>
								    </Menu>
								</ListItemIcon>
								<ListItemText
									primary={
										auth.user?.display_name ||
										window.localStorage.getItem(K.SAVED_USER_EMAIL_ADDRESS)
									}
								/>
							</ListItem>
						</>
					) : (
						navShown() && (
							<div className={styles.nav}>
								<NavLink
									to="/"
									className={styles.navLink}
									exact
									activeClassName={styles.activeLink}
									onClick={onClose}
								>
									Home
								</NavLink>
								<NavLink
									to="/about/"
									className={styles.navLink}
									exact
									activeClassName={styles.activeLink}
									onClick={onClose}
								>
									About
								</NavLink>
								<NavLink
									to="/pricing/"
									className={styles.navLink}
									exact
									activeClassName={styles.activeLink}
									onClick={onClose}
								>
									Pricing
								</NavLink>
								<a
									href={process.env.REACT_APP_BLOG_URL}
									target="_blank"
									rel="noopener noreferrer"
									className={styles.navLink}
									onClick={onClose}
								>
									Blog
								</a>
								<NavLink
									to="/contact/"
									className={styles.navLink}
									exact
									activeClassName={styles.activeLinkC}
									onClick={onClose}
								>
									Contact Us
								</NavLink>
								{/* <NavLink to="/login" exact className={clsx(styles.navLink, styles.loginButton)}>
								Login
							</NavLink> */}
							</div>
						)
					)}
				</List>
			</Drawer>
		</Hidden>
	);
};

const mapStateToProps = (state: RootState) => ({
	auth: state.auth,
});
const connector = connect(mapStateToProps, { logoutUser });

export default connector(MobileNav);
