import React, { Suspense } from 'react';
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';
import { useLocation } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { makeStyles } from '@mui/styles';
import { HelmetProvider } from 'react-helmet-async';
import Header from '../components/Header';
import MobileNav from '../components/Header/MobileNav';

/**
 * main layout component wrapper for entire application
 */

const useStyles = makeStyles({
	main: {
		height: '100%',
		overflowY: 'hidden',
		position: 'relative',
	},
	body: {
		height: 'calc(100vh - 82px)',
		overflowY: 'auto',
		marginTop: '82px',
		width: '100%',
	},
	loading: {
		minHeight: '70vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
});

const MainLayout: React.FC<RouteConfigComponentProps> = ({ route }) => {
	const [openMobileNav, setOpenMobileNav] = React.useState(false);
	const location = useLocation();
	const styles = useStyles();

	React.useEffect(() => {
		// scroll to top on route change
		document.getElementById('body-elem')?.scrollTo(0, 0);
	}, [location.pathname]);

	const navShown = (): boolean => {
		// show navbar always. Is there a case where we wouldn't want to show navbar?
		return true;
	};

	return (
		<HelmetProvider>
			<div className={styles.main}>
				{navShown() && (
					<>
						<Header openMobileNav={() => setOpenMobileNav(true)} />
						<MobileNav mobileNavOpen={openMobileNav} onClose={() => setOpenMobileNav(false)} />
					</>
				)}
				<div className={styles.body} id="body-elem">
					<Suspense
						fallback={
							<div className={styles.loading}>
								<Loader type="ThreeDots" color="#0A66C2" height={20} />
							</div>
						}
					>
						<div>{renderRoutes(route?.routes)}</div>
					</Suspense>
					{navShown()}
				</div>
			</div>
		</HelmetProvider>
	);
};

export default MainLayout;
