import { Dispatch } from 'redux';
import API from '../network/api';
import setAuthToken from '../network/set-auth-token';
import { setUser } from '../store/modules/auth.reducer';
import K from '../constants';

/**
 * Function called to check the auth state of a user
 * @param accessToken - auth token required for checking authentication status
 * @param dispatch - dispatch method for running redux actions
 */
export default async function checkAuth(
	accessToken: string,
	dispatch: Dispatch<any>,
	removeCookies: Function
) {
	setAuthToken(accessToken);
	const user = window.localStorage.getItem(K.SAVED_USER);
	if (user) {
		dispatch(setUser(JSON.parse(user)));
		try {
			await API.post(`/auth/verify_token/`, {
				token: accessToken,
			});
		} catch (error) {
			window.localStorage.removeItem(K.SAVED_USER);
			setAuthToken(null);
			dispatch(setUser(null));
			removeCookies(K.ACCESS_TOKEN);
			window.location.href = '/';
		}
	} else {
		setAuthToken(null);
		dispatch(setUser(null));
		removeCookies(K.ACCESS_TOKEN);
		window.location.href = '/';
	}
}
