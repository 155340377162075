import React, {useState} from 'react';
import { renderRoutes } from 'react-router-config';
import { BrowserRouter, Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useIdleTimer } from 'react-idle-timer';
import CookieConsent from 'react-cookie-consent';
import decodeToken from 'jwt-decode';
import routes from './routes';
import K from './constants';
import checkAuth from './utils/check-auth';
import { light, dark } from './styles/theme';
import { ThemeContext } from './styles/theme/ThemeContext';
import { logoutUser, setUser } from './store/modules/auth.reducer';
import IdleModal from './components/IdleModal';
import { RootState } from './store/root';
import setAuthToken from './network/set-auth-token';
import useGoogleOneTap from './utils/use-google-one-tap';
import { GoogleSignInResponse } from './@types';
import API from './network/api';
import 'react-responsive-modal/styles.css';
import './css/custom_table_styles.css';

const useStyles = makeStyles ({
    root: {
        '& > div > div > button': {
            '&:hover': {
                boxShadow: 'none!important',
            },
        },
    },
    link: {
        color: '#000',
        display: 'inline-block',
        fontWeight: 'bold',
        '&:hover': {
            color: '#000',
        },
    },
    textP: {
        margin: 0,
        width: '20rem',
        fontSize: '0.775rem',
        textAlign: 'left',
        '@media (max-width: 424px)': {
            margin: '0.1rem -0.5rem -0.5rem',
            width: 'auto',
            fontSize: '0.7rem',
        },
        '@media (min-width: 2500px)': {
            fontSize: '0.8rem',
        },
        '& > strong': {
            fontSize: '1rem',
        },
    },
});

const App: React.FC<{}> = () => {
	const [cookies, setCookies, removeCookies] = useCookies([K.ACCESS_TOKEN]);
	const [showCookie, setShowCookie] = useState(true);
	const dispatch = useDispatch();
	const { theme } = React.useContext(ThemeContext);
	const [idleModalOpen, setIdleModalOpen] = React.useState(false);
	const [timeLeft, setTimeLeft] = React.useState('');
	const [timerInterval, setTimerInterval] = React.useState<any>(null);
	const auth = useSelector((state: RootState) => state.auth);
	const classes = useStyles();

	// Hide cookie after 10 seconds
	setTimeout(() => {
		setShowCookie(false);
		}, 10000);

	const googleSignInCallback = React.useCallback(
		async (gResponse: GoogleSignInResponse) => {
			try {
				const decoded: any = decodeToken(gResponse.credential);
				const { data } = await API.post('/user/', {
					email_id: decoded.email,
					is_otp: false,
					first_name: decoded.given_name,
					last_name: decoded.family_name,
				});
				window.localStorage.setItem(K.SAVED_USER_ID, data.user_id);
				const response = await API.post('/auth/', {
					username: decoded.email,
					password: data.temp_passcode,
				});
				setCookies(K.ACCESS_TOKEN, response.data.access, {
					expires: new Date(new Date().setHours(new Date().getHours() + 1)), // expire token in an hour
				});
				setAuthToken(response.data.access);

				const authResponse = await API.get(`/user_info/?username=${decoded.email}`);
				window.localStorage.setItem(K.SAVED_USER, JSON.stringify(authResponse.data));

				dispatch(setUser(authResponse.data));
			} catch (error) {}
		},
		[dispatch, setCookies]
	);
	useGoogleOneTap({ callback: googleSignInCallback });

	// effect for initializing facebook SDK
	// React.useEffect(() => {
	// 	(window as any).fbAsyncInit = function () {
	// 		(window as any).FB.init({
	// 			appId: process.env.REACT_APP_FACEBOOK_APP_ID,
	// 			autoLogAppEvents: true,
	// 			xfbml: true,
	// 			version: 'v10.0',
	// 		});
	// 	};
	// }, []);

	const authCheck = React.useCallback(async () => {
		if (cookies[K.ACCESS_TOKEN]) {
			await checkAuth(cookies[K.ACCESS_TOKEN], dispatch, removeCookies);
		} else {
			setAuthToken(null);
			dispatch(setUser(null));
		}
	}, [dispatch, removeCookies]); //eslint-disable-line

	React.useEffect(() => {
		authCheck();
		return () => {
			clearInterval(timerInterval);
		};
	}, [authCheck, timerInterval]);

	const onIdle = () => {
		if (!timerInterval && auth.authenticated) {
			let seconds = 60;
			setTimeLeft(`${seconds} secs`);
			setIdleModalOpen(true);

			let interval = setInterval(() => {
				seconds--;
				setTimeLeft(`${seconds} secs`);

				if (seconds === 0) {
					clearInterval(timerInterval);
					setIdleModalOpen(false);
					setTimerInterval(null);
					dispatch(logoutUser(removeCookies));
				}
			}, 1000);
			setTimerInterval(interval);
		}
	};

	useIdleTimer({
		timeout: 1000 * 60 * K.IDLE_TIME_MINUTES,
		onIdle,
		startOnMount: true,
	});

	// @ts-ignore
	return (
		<ThemeProvider theme={theme === 'light' ? light : dark}>
			<BrowserRouter>
					{/* css resets */}
					<CssBaseline />

					{/* render application routes */}
					{renderRoutes(routes)}

					{/* idle modal */}
					<IdleModal
						open={idleModalOpen}
						onClose={() => {
							clearInterval(timerInterval);
							setIdleModalOpen(false);
							setTimerInterval(null);
						}}
						logOut={() => {
							clearInterval(timerInterval);
							setIdleModalOpen(false);
							setTimerInterval(null);
							dispatch(logoutUser(removeCookies));
						}}
						time={timeLeft}
					/>

					<ToastContainer />
					{/* cookie consent banner */}
					<div className={classes.root}>
						{ showCookie &&
							<CookieConsent
								location="bottom"
								buttonText="🍪"
								buttonClasses="cookieButton"
								style={{
									background: '#fff',
									color: '#000',
									margin: '0.3rem',
									width: 'auto',
									borderRadius: '4px',
									display: 'flex',
									alignItems: 'center',
									border: '2px solid #0a66c2',
								}}
								buttonStyle={{
									margin: '0px 8px 0px 0px',
									padding: 0,
									background:'#ffffff00',
									fontSize: '2.8rem',
									transition: 'transform .22s',
									borderRadius: '2rem',
									boxShadow: 'none!important',
									width:'100%',
								}}
							>
								<strong>We got a cookie for you!</strong>
								<p className={classes.textP}>
									Cookies help us deliver you the best browsing experience. We respect your{' '}
									<Link to="/privacy-policy/" className={classes.link}>privacy</Link>{' '}.
								</p>
							</CookieConsent>
						}
					</div>
			</BrowserRouter>
		</ThemeProvider>
	);
};

export default App;