import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead,
TableRow, Paper, TablePagination} from "@mui/material";
import { makeStyles } from '@mui/styles';
import DownloadIcon from '@mui/icons-material/Download';
import API from "../../network/api";
import {useHistory} from "react-router";

const useStyles = makeStyles ({
	table: {
		width: "100%",
		boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
	},
	tableRow: {
		lineHeight: "1.5rem",
		fontSize: "1rem!important",
		padding: "1rem 2rem!important",
		'@media (max-width: 445px)': {
		    padding: "1rem 1rem!important",
		},
	},
	tableHead: {
		lineHeight: "2rem",
		fontSize: "1rem!important",
		fontWeight: "bold",
		padding: "1rem 2rem!important",
		'@media (max-width: 445px)': {
			padding: "0rem 1rem!important",
		},
	},
	tablePagination: {
	    "& > div": {
	        '@media (max-width: 425px)': {
	            padding: 0,
	        },
	        "& > div": {
	            '@media (max-width: 425px)': {
	                margin: 0,
	            },
	            "& > button": {
	                '@media (max-width: 425px)': {
	                    padding: "3px",
	                },
	            },
	        },
	    },
	},
});

const SupportedCountiesTable: React.FC<{}> = () => {
	const history = useHistory();
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(30);
	const [counties, setCounties] = React.useState<any>();

	React.useEffect(() => {
		async function Supported() {
			try {
				const { data } = await API.get(`/us_state_county/`, {
					params: { comparable: true },
				});
				if (data) {
					setCounties(data);
				}
			} catch (e) {
				history.push('/500');
			}
		}
		Supported();
	}, [history]);
	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => {
		setPage(newPage);
	};
	const classes = useStyles();
	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<div>
			<TableContainer component={Paper} className={classes.table}>
				<Table aria-label='simple table'>
					<TableHead>
						<TableRow>
							<TableCell className={classes.tableHead}>State</TableCell>
							<TableCell align='left' className={classes.tableHead}>
								County
							</TableCell>
							<TableCell align='left' className={classes.tableHead}>
								Sample
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody style={{backgroundColor:'white'}}>
						{counties &&
							(rowsPerPage > 0
								? counties.slice(
										page * rowsPerPage,
										page * rowsPerPage + rowsPerPage
								  )
								: counties
							).map((val: any) => (
								<TableRow key={val.county_id}>
									<TableCell
										component='th'
										scope='row'
										className={classes.tableRow}
									>
										{val.state_code}
									</TableCell>
									<TableCell align='left' className={classes.tableRow}>
										{val.county_name}
									</TableCell>
									<TableCell align='left' className={classes.tableRow}>
										<a href={val.sample_pdf_url} target="_blank" rel="noreferrer" download>
											<DownloadIcon/>
										</a>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
			{counties && counties.length > 30 && (
				<TablePagination
				    component="div"
				    rowsPerPageOptions={[30, 60, 90]}
				    count={counties && counties.length}
				    page={page}
				    onPageChange={handleChangePage}
				    rowsPerPage={rowsPerPage}
				    onRowsPerPageChange={handleChangeRowsPerPage}
				    className={classes.tablePagination}
				/>
			)}
		</div>
	);
};

export default SupportedCountiesTable;